<template>
	<div>
		<div class="generic-title">About Us</div>
		<div class="legal-content">
			<div v-html="content.text"></div>
		</div>
	</div>
</template>

<script>

import getContent from '@/utils/get-content';

export default {

	name: 'AboutPage',

	async asyncData(context) {
		return {
			content: await getContent(context, 'about-us'),
		};
	},

	data() {
		return {
			content: {},
		};
	},

};

</script>
